import '@justgiving/loading/src/Loading.scss';
import './HomePage.scss';
import '@justgiving/totaliser/src/Totaliser.scss';

import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import * as Sentry from '@sentry/browser';

import React, { Suspense } from 'react';

import { ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';
import { client } from '../graphqlClient';
import config from '../config';
import i18n from '../i18next';
import { render } from 'react-dom';
import {
  getCookie,
  manageAnalyticsCookie,
} from '../utils/manageAnalyticsCookie';
import { auth } from '../utils/auth';
import isMobile from '../utils/isMobile';

export function initHomePage(Layout: JSX.Element) {
  const optimizely = createInstance({
    sdkKey: config.optimizelySdkKey,
  });

  Sentry.init({
    dsn: config.sentryDsn,
    release:
      document
        .querySelector('meta[data-application-version]')
        ?.getAttribute('data-application-version') ?? 'local',
    environment: config.env,
    whitelistUrls: [/justgiving\.com/, /jg-cdn\.com/],
    sampleRate: 0.1,
    ignoreErrors: [
      'Blocked a frame with origin "https://www.justgiving.com"',
      /^Non-Error promise rejection/,
    ],
  });

  manageAnalyticsCookie(auth);
  let analyticsCookieValue = decodeURIComponent(
    getCookie(config.analyticsCookieName) as string
  );

  const loggedOutGuid =
    analyticsCookieValue &&
    analyticsCookieValue.substring(
      analyticsCookieValue.indexOf('logged_out_guid=') + 16
    );

  const deviceType = isMobile(window) ? 'Mobile' : 'Desktop';

  const attributes = {
    deviceType,
  };

  render(
    <Suspense fallback={<div />}>
      <OptimizelyProvider
        optimizely={optimizely}
        user={{
          id: loggedOutGuid || null,
          attributes,
        }}
      >
        <I18nextProvider i18n={i18n}>
          <ApolloProvider client={client}>{Layout}</ApolloProvider>
        </I18nextProvider>
      </OptimizelyProvider>
    </Suspense>,
    document.getElementById('app')
  );
}
