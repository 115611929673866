// @ts-nocheck
import { FunctionComponent } from 'react';
import { loadClient as loadChrome } from '@justgiving/chrome';
import { useDecision } from '@optimizely/react-sdk';
import { Experiments } from '../../constants/experiments';

export const Chrome: FunctionComponent = () => {

    const [ShowGroupedNavLinksExperiment] = useDecision(
        Experiments.ShowGroupedNavLinks.flagKey
    );

    loadChrome({
        headerVariant: ShowGroupedNavLinksExperiment?.enabled
            ? 'homepageNavGroupedHeader'
            : 'homepage',
        footerVariant: 'homepage',
        searchOpen: true,
    });

    return null;
};